.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 250px;
  height: 50px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  margin-left: auto;
  margin-right: auto;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 125px;
  height: 45px;
  border-radius: 45px;
  transition: 0.2s;
  background: rgba(255, 255, 255, 0.31);
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 100px;
}

.right-toggle {
  font-weight: 0;
  color: #f7f7f8;
  font-weight: 100;
  font-size: 1.2em;
  float: right;
  margin-right: 27px;
}

.left-toggle {
  float: left;
  font-weight: 100;
  color: #f7f7f8;
  font-size: 1.2em;
  margin-left: 45px;
}

.passive-toggle {
  color: black;
}

.toggle-box {
  width: inherit;
}
